<template>
    <b-modal
        v-bind="$attrs"
        ref="modal"
        size="xl"
        scrollable
        centered
        title="Печать составов"
        footer-class="d-flex justify-content-center"
        @hide="onClose"
    >
        <b-row>
            <b-col cols="12">
                <errors-bag-list/>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="8" xl="8" sm="12" class="d-flex justify-content-center align-items-center">
                <b-img v-if="nomenclatureImage" :src="nomenclatureImage" fluid width="310px"/>
                <b-spinner v-else variant="dark"/>
            </b-col>
            <b-col md="4" xl="4">
                <b-form-group>
                    <template #label>
                        Принтер <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                    </template>
                    <printer-select v-model="printerId"/>
                </b-form-group>
                <b-form-group>
                    <template #label>
                        Тип этикетки <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                    </template>
                    <label-type-select v-model="labelType"/>
                </b-form-group>
                <b-form-group>
                    <template #label>
                        Количество этикеток <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                    </template>
                    <b-form-input
                        type="number"
                        v-model.number="numberOfLabels"
                        autocomplete="off"
                    />
                </b-form-group>
                <b-form-group v-if="availableToSelectProductionDate">
                    <b-form-checkbox v-model="printDate">Дата производства</b-form-checkbox>
                    <b-form-datepicker
                        v-if="printDate"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :start-weekday="1"
                        locale="ru"
                        placeholder=""
                        :max="maxProductionDate"
                        v-model="productionDate"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="nomenclature">
            <b-col cols="12">
                <h5>{{ nomenclature.name }}</h5>
            </b-col>
        </b-row>
        <b-row v-if="nomenclature && nomenclature.nomenclatureComposition">
            <b-col cols="12">
                <p class="text-justify">{{ composition }}</p>
            </b-col>
        </b-row>

        <template #modal-footer="{cancel}">
            <b-button @click="sendToPrint" variant="success">Отправить на печать</b-button>
            <b-button @click="cancel" variant="danger">Отмена</b-button>
        </template>
    </b-modal>
</template>

<script>
import moment from 'moment';
import ErrorsBag from '@utils/errorsBag';
import ErrorsBagList from '@components/_common/ErrorsBagList';

import LabelTypeSelect from './components/LabelTypeSelect';
import PrinterSelect from './components/PrinterSelect';
import {NomenclatureService, PrinterQueueService} from '@services';

const WITH = ['nomenclatureComposition'];

export default {
    name: 'Printing',
    props: {
        value: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        nomenclatureId: {
            type: Number,
            required: true,
        },
        isResellerNomenclature: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        productionPlanIds: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    components: {
        ErrorsBagList,
        LabelTypeSelect,
        PrinterSelect,
    },
    computed: {
        nomenclatureImage() {
            return this.$store.getters["nomenclature/info/image"](this.nomenclatureId)
        },
        composition() {
            let composition = '';

            if (this.nomenclature && this.nomenclature.nomenclatureComposition) {
                composition = [
                    this.nomenclature.nomenclatureComposition.head,
                    this.nomenclature.nomenclatureComposition.body,
                    this.nomenclature.nomenclatureComposition.footer
                ]
                .filter(Boolean)
                .join("\n");
            }

            return composition;
        },
        maxProductionDate() {
            return moment().add(1, 'days').format('YYYY-MM-DD');
        },
        availableToSelectProductionDate() {
            return this.labelType === 'pack_with_short_rotate_barcode' && !this.isResellerNomenclature;
        },
    },
    watch: {
        labelType: {
            handler: function () {
                this.printDate = true
            }
        },
        nomenclatureId: {
            handler: function () {
                this.$store.dispatch("nomenclature/info/getNomenclatureInfo", this.nomenclatureId);
                this.loadNomenclature();
            }
        },
    },
    data() {
        return {
            nomenclature: null,
            printerId: null,
            labelType: '',
            numberOfLabels: null,
            printDate: true,
            productionDate: moment().format('YYYY-MM-DD'),
        }
    },
    methods: {
        onClose() {
            ErrorsBag.discard();

            this.printerId = null;
            this.printDate = true;
            this.labelType = null;
            this.numberOfLabels = null;
        },
        loadNomenclature() {
            ErrorsBag.discard();
            NomenclatureService.getById(this.nomenclatureId, {
                without_loading: true,
                with: WITH,
            })
            .then(({data}) => this.nomenclature = data)
            .catch(() => {
                this.$bvToast.toast('Не удалось загрузить номенклатуру и состав', {variant: 'danger'});
            });
        },
        sendToPrint() {
            ErrorsBag.discard();
            PrinterQueueService.sendToPrintBottomLabel({
                nomenclatureId: this.nomenclatureId,
                production_plan_ids: this.productionPlanIds,
                printerId: this.printerId,
                labelType: this.labelType,
                numberOfLabels: this.numberOfLabels,
                productionDate: this.printDate ? moment(this.productionDate).format('YYYY-MM-DD') : null,
                without_loading: true,
            })
            .then(() => {
                this.$bvToast.toast('Этикетки успешно отправлены на печать', {variant: 'success'});
                this.$refs['modal'].hide();
            })
            .catch((response) => {
                ErrorsBag.fill(Object.values(response.data.errors).flat());
            });
        },
    },
}
</script>
