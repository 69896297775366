<template>
    <b-form-select v-model="model" :options="options"/>
</template>

<script>
export default {
    name: 'PrinterSelect',
    props: {
        value: {
            type: Number,
        },
    },
    data() {
        return {
            options: [
                {text: 'Зебра №35', value: 35},
                {text: 'Зебра №39', value: 39},
                {text: 'Зебра №48', value: 48},
                {text: 'Тестовый принтер', value: 9999},
                {text: 'PRM-TS22 (Фасовка)', value: 49},
                {text: 'PRM-TS23 (Фасовка)', value: 50},
            ],
        };
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
    },
}
</script>
