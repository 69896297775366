<template>
    <b-form-select v-model="model" :options="options"/>
</template>

<script>
export default {
    name: 'LabelTypeSelect',
    props: {
        value: {
            type: String,
        },
    },
    data() {
        return {
            options: [
                { text: 'Лоток (короткий/повернутый ШК)', value: 'pack_with_short_rotate_barcode'},
                { text: 'Прием', value: 'priem'},
                { text: 'Бутылка', value: 'bottle_2' }
            ],
        }
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
    },
}
</script>
