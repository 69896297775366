<template>
    <div class="card">
        <div class="card-body" style="padding: 5px !important;">
            <div v-show="tab === 'tasks'">
                <task-list @select="lotSelected($event)"
                           ref="task-list"></task-list>
            </div>
            <div v-show="tab === 'movement'">
                <onto-zone ref="movement" v-if="nomenclatureLotId"/>
            </div>
            <div v-show="tab === 'packing'">
                <packing v-if="packingAvailable"
                    ref="packing"
                    @loaded="$forceUpdate()"
                    :production-plan-ids="productionPlanIds"
                    :nomenclature-lot-ids="nomenclatureLotIds"
                    :nomenclature-lot-id="nomenclatureLotId"
                    :nomenclature-id="nomenclatureId"
                    :nomenclature-name="nomenclatureName"
                />
            </div>
        </div>
        <control-panel style="z-index: 900 !important;">
            <button class="btn" @click="$router.go(-1)">
                <i class="fas fa-angle-left"></i>
            </button>
            <button class="btn"
                    @click="togglePacking()"
                    :disabled="!packingAvailable"
                    :class="{'btn-success': tab === 'packing'}">
                <i v-if="attaching" class="fas fa-spinner fa-spin"></i>
                <i v-else class="fas fa-inbox"></i>
            </button>
            <button class="btn"
                    :class="{'btn-success': tab === 'movement'}"
                    @click="toggleMovement()"
                    :disabled="!movementAvailable()"><i class="fas fa-pallet"></i></button>
            <button class="btn"
                    @click="toggleTasks()"
                    :class="{'btn-success': tab === 'tasks'}"><i class="fas fa-tasks"></i></button>
        </control-panel>
    </div>
</template>

<script>
    import ControlPanel from "../../_common/ControlPanel";
    import TaskList from "./TaskList";
    import Packing from "./Packing";
    import OntoZone from "../Movement/OntoZone";
    import {findIndex, sumBy} from 'lodash';

    export default {
        name: "PackingWrapper",
        components: {
            ControlPanel,
            TaskList,
            Packing,
            OntoZone,
        },
        data() {
            return {
                tab: 'tasks',
                count: 13,
                productionPlanIds: [],
                nomenclatureLotIds: [],
                nomenclatureLotId: null,
                nomenclatureId: null,
                nomenclatureName: null,
                boxes: [],
                loading: false,
                attaching: false,
                loadingAmount: false,
                amount: 0,
                showPhoto: false,
            };
        },
        watch: {
            tab(val) {
                if (val === 'packing' || val === 'movement') {
                    this.$root.listenToBarcodeScanning(this.$refs[val]);
                }
            }
        },
        computed: {
            packingAvailable() {
                return !!this.nomenclatureLotId;
            },
        },
        methods: {
            sumBy,
            movementAvailable() {
                return !!this.$refs.packing ? !!this.$refs.packing.contents.length : false;
            },
            toggleTasks() {
                if (this.tab === 'tasks') {
                    this.$refs['task-list'].load();
                } else {
                    this.tab = 'tasks';
                }
            },
            togglePacking() {
                if (this.tab === 'packing') {
                    this.$refs['packing'].load();
                } else {
                    this.tab = 'packing';
                }
            },
            toggleMovement() {
                if (this.tab === 'movement') {
                    this.$refs['movement'].load();
                } else {
                    this.tab = 'movement';
                }
            },
            lotSelected(data) {
                this.productionPlanIds = data.productionPlanIds;
                this.nomenclatureLotIds = data.nomenclatureLotIds;
                this.nomenclatureLotId = data.nomenclatureLotId;
                this.nomenclatureId = data.nomenclatureId;
                this.nomenclatureName = data.nomenclatureName;
                this.togglePacking();
            },
        }
    }
</script>

<style>

</style>
